<div *ngIf="!isOpen && !alwaysOpen">
  <ng-container *ngIf="form.get(name).value as employeeId">
    <ng-container *ngIf="employeeId; else noEmployeeSelected">
      <ng-container *ngIf="employeeId === 'open_shift'; else employee">
        <select (mousedown)="open($event)" class="form-modal__input select">
          <option selected>{{ 'Turn this shift into an open shift' | translate }}</option>
        </select>
      </ng-container>
      <ng-template #employee>
        <select *ngIf="employees[employeeId] as employee" (mousedown)="open($event)" class="form-modal__input select">
          <option selected>
            {{ employee.name }}
            <ng-container *permission="'View salary'; user: employee.id"> - {{ employee.cost | money }}</ng-container>
          </option>
        </select>
      </ng-template>
    </ng-container>

    <ng-template #noEmployeeSelected>
      <select (mousedown)="open($event)" class="form-modal__input select"></select>
    </ng-template>
  </ng-container>
</div>

<div *ngIf="isOpen || alwaysOpen" [formGroup]="form" [ngClass]="{ 'has-danger': hasDanger }">
  <dl-select
    [items]="items"
    [config]="{
      hasAllCheckBox: false,
      hasFilter: true,
      hasConflictFilterCheckBox: true,
      hasCollapseExpand: false,
      maxHeight: 250
    }"
    [itemTemplate]="employeeItem"
    [formControlName]="name"
    (conflictFilter)="toggleConflictFilter($event)"
  ></dl-select>

  <ng-container *subscriptionPlan="planType.BASIC">
    <button *ngIf="moveToOpenShifts" type="button" class="btn btn-warning" (click)="selectOpenShift()">
      {{ 'Move to open shifts' | translate }}
    </button>
  </ng-container>

  <control-messages [control]="form.get(name)"></control-messages>

  <ng-template #employeeItem let-item="item">
    <div
      [ngClass]="{
        'select-employee': !item.children && employees[item.value],
        'select-team': item.children && teams[item.value]
      }"
    >
      <div *ngIf="item.children && teams[item.value]; let team">
        <strong>{{ team.name }}</strong>
      </div>

      <ng-container *ngIf="!item.children && employees[item.value]; let employee">
        <employee-select-item
          [employee]="employee"
          [date]="date"
          [selected]="item.checked"
          (employeeSelected)="select(item)"
          [assignTmp]="assignTmp"
        ></employee-select-item>
      </ng-container>
    </div>
  </ng-template>
</div>
