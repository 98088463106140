<div
  (click)="employeeSelected.emit()"
  [ngClass]="{
    selected: selected,
    'bg-error-100': !!employee.employability && !employee.employability?.employable,
    lacksRequiredSkills: employee?.onlyLackSkills,
    'bg-success-100': employee.employability?.employable && employee.employability?.details?.availability?.available
  }"
>
  <div class="avatar-item border-l-0">
    <div class="avatar-item__container">
      <sb-avatar [employee]="employee" [size]="30"></sb-avatar>
    </div>

    <div class="avatar-item__details">
      <div class="d-flex justify-between">
        <span class="avatar-item__title">{{ employee.name }}</span>
        <ng-container *subscriptionPlan="planType.BASIC">
          <span class="avatar-item__stats" *permission="'View salary'; user: employee.id">
            {{ employee.cost | money }}
          </span>
        </ng-container>
      </div>
    </div>
    <div (click)="$event.stopImmediatePropagation()">
      <ng-container [ngTemplateOutlet]="assignTmp" [ngTemplateOutletContext]="{ employee: employee }"></ng-container>
    </div>
  </div>

  <ng-container *subscriptionPlan="planType.BASIC">
    <div *ngIf="!!employee.employability?.details.availability" class="flex justify-between px-1">
      <span class="select-employee__title min-w-[120px]">{{ 'Availability' | translate }}</span>

      <span class="select-employee__value grow">
        {{ employee.employability.details.availability.description }}
      </span>
    </div>
  </ng-container>

  <ng-container *subscriptionPlan="planType.BASIC">
    <div
      *ngFor="let absence of employee.employability?.details.absence; let isFirst = first; trackBy: trackById"
      class="flex justify-between px-1"
    >
      <span class="select-employee__title min-w-[120px]"
        ><ng-container *ngIf="isFirst">{{ 'Absence' | translate }}</ng-container></span
      >

      <span class="select-employee__value grow">
        {{ absence }}
      </span>
    </div>
  </ng-container>

  <ng-container>
    <div
      *ngFor="let shift of employee.employability?.details?.schedule; let isFirst = first; trackBy: trackById"
      class="flex justify-between px-1"
    >
      <span class="select-employee__title min-w-[120px]"
        ><ng-container *ngIf="isFirst">{{ 'Schedule' | translate }}</ng-container></span
      >

      <span class="select-employee__value grow">{{ shift }}</span>
    </div>
  </ng-container>

  <ng-container *subscriptionPlan="planType.EARLY_ADOPTER">
    <div *ngIf="employee.employability?.details?.missingSkills?.length" class="flex justify-between gap-3 px-1">
      <span class="select-employee__title min-w-[120px]">{{ 'Missing skills' | translate }}</span>
      <span class="select-employee__title flex grow flex-col">
        <span
          *ngFor="let skill of employee.employability?.details?.missingSkills; let isLast = last; trackBy: trackById"
          class="select-employee__value select-employee__skill"
        >
          {{ skill }}
          <ng-container *ngIf="!isLast">,</ng-container>
        </span>
      </span>
    </div>
  </ng-container>
</div>
