<div *ngIf="!isOpen">
  <ng-container *ngIf="form.get(name).value as employeeIds">
    <ng-container *ngIf="employeeIds.length > 0; else noEmployeeSelected">
      <ng-container *ngIf="employeeIds.length === 1; else multipleSelected">
        <select
          *ngIf="employees[employeeIds[0]] as employee"
          (mousedown)="open($event)"
          class="form-modal__input select"
        >
          <option selected>
            {{ employee.name }}
            <ng-container *permission="'View salary'; user: employee.id" class="avatar-item__stats">
              - {{ employee.cost | money }}</ng-container
            >
          </option>
        </select>
      </ng-container>

      <ng-template #multipleSelected>
        <select (mousedown)="open($event)" class="form-modal__input select">
          <option selected>{{ employeeIds.length }}&nbsp;{{ 'selected' | translate }}</option>
        </select>
      </ng-template>
    </ng-container>

    <ng-template #noEmployeeSelected>
      <select (mousedown)="open($event)" class="form-modal__input select"></select>
    </ng-template>
  </ng-container>
</div>

<div *ngIf="isOpen" [formGroup]="form" [ngClass]="{ 'has-danger': hasDanger }">
  <dl-multiselect
    [items]="items"
    [config]="{
      hasAllCheckBox: true,
      hasFilter: true,
      hasConflictFilterCheckBox: true,
      hasCollapseExpand: false,
      maxHeight: 250
    }"
    [itemTemplate]="employeeItem"
    [formControlName]="name"
    (conflictFilter)="toggleConflictFilter($event)"
  ></dl-multiselect>

  <control-messages [control]="form.get(name)"></control-messages>

  <button (click)="close()" type="button" class="btn btn-primary" translate>Done</button>

  <ng-template
    #employeeItem
    let-item="item"
    let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange"
  >
    <div
      [ngClass]="{
        'select-employee': !item.children && employees[item.value],
        'select-team': item.children && teams[item.value]
      }"
    >
      <div *ngIf="item.children && teams[item.value]; let team" (click)="itemClicked(onCheckedChange, item)">
        <strong>{{ team.name }}</strong>
      </div>

      <ng-container *ngIf="!item.children && employees[item.value]; let employee">
        <employee-select-item
          [employee]="employee"
          [selected]="item.checked"
          (click)="itemClicked(onCheckedChange, item)"
          [date]="date"
        ></employee-select-item>
      </ng-container>
    </div>
  </ng-template>
</div>
