import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PermissionDirective } from '@app/+authenticated/shared/permission.directive';
import { MoneyPipe } from '@app/pipes/money.pipe';
import { TranslateModule } from '@ngx-translate/core';

import { PlanType } from '../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { SBAvatarComponent } from '../sb-lib/avatar/sb-avatar.component';
import { SubscriptionPlanDirective } from '../subscription-plan/subscription-plan.directive';
import { trackByIdFn } from '../trackby';
import { EmployeeSelectItemViewModel } from './employee-select-items.selector';

@Component({
  selector: 'employee-select-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './employee-select-item.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SubscriptionPlanDirective,
    SBAvatarComponent,
    MoneyPipe,
    PermissionDirective,
    TranslateModule,
  ],
})
export class EmployeeSelectItemComponent {
  @Input()
  public selected: boolean;
  @Input()
  public employee: EmployeeSelectItemViewModel;
  @Input()
  public date: string;

  @Output()
  public employeeSelected = new EventEmitter();

  @Input()
  public assignTmp: TemplateRef<any>;

  public planType = PlanType;

  public lacksRequiredSkills: boolean;

  public trackById = trackByIdFn;
}
