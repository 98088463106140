import { getAccountCocInSchedule } from '@app/reducers/account/account.service';
import { ContractModel } from '@app/reducers/orm/contract/contract.model';
import { contractInfoPerEmployeePerDay, getContracts } from '@app/reducers/orm/contract/contract.service';
import { EmployabilityModel } from '@app/reducers/orm/employability/employability.model';
import { getEmployabilityEntities } from '@app/reducers/orm/employability/employability.selector';
import { EmployeeModel } from '@app/reducers/orm/employee/employee.model';
import { getEmployeeEntities } from '@app/reducers/orm/employee/employee.service';
import { createSelector } from '@ngrx/store';

export interface EmployeeSelectItemViewModel extends EmployeeModel {
  wage: string;
  cost: string;
  coc?: string; // todo make it non-optional when removing the feature flag TMP_REPLACE_WAGE_WITH_CTC
  employability: EmployabilityModel;
  onlyLackSkills: boolean;
}

export const selectEmployeeSelectItemsViewModel = (date: string) =>
  createSelector(
    getEmployeeEntities,
    getContracts,
    getEmployabilityEntities,
    getAccountCocInSchedule,
    (employees, contracts: ContractModel[], employabilityEntities, accountCocInSchedule) => {
      const contractPerDay = contractInfoPerEmployeePerDay(date, date, contracts);
      return Object.entries(employees).reduce(
        (acc, [key, employee]) => {
          const employability = employabilityEntities[employee.id];
          const employeeContractOnDate = contractPerDay[employee.id]?.[date];
          // either the wage from the employability or the wage from the contract to match the current behavior
          const wage = employability?.details?.wage || employeeContractOnDate?.wage || '0.00';
          const coc = employability?.details?.coc || employeeContractOnDate?.coc || '0.00';
          const onlyLackSkills =
            !employability?.employable &&
            !!employability?.details &&
            !employability?.details?.schedule?.length &&
            !employability?.details?.absence?.length &&
            !employability?.details?.availability;
          acc[key] = {
            ...employee,
            wage,
            coc,
            cost: accountCocInSchedule ? coc : wage,
            employability,
            onlyLackSkills,
          };
          return acc;
        },
        {} as Record<string, EmployeeSelectItemViewModel>,
      );
    },
  );
